import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import legalRoutes from "@/router/legalRoutes";

Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior() {
    // Des fois le scroll ne se fait pas ; ce hack règle le problème.
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  },
  mode: "history",
  routes: [
    ...legalRoutes,
    {
      path: "/",
      alias: "/accueil",
      name: "accueil",
      component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    },
    {
      path: "/compte",
      name: "compte",
      component: () => import(/* webpackChunkName: "compte" */ "../views/Compte.vue"),
    },
    {
      path: "/rgpd",
      name: "rgpd",
      meta: {
        public: true,
      },
      component: () => import(/* webpackChunkName: "rgpd" */ "@/views/legal/Rgpd.vue"),
      children: [],
    },
    {
      path: "/statuts-services",
      name: "statuts_services",
      component: () => import(/* webpackChunkName: "statuts_services" */ "@/views/StatutsServices.vue"),
      children: [],
    },
    {
      path: "/stats/:name",
      name: "stats",
      component: () => import(/* webpackChunkName: "stats" */ "@/views/Stats.vue"),
      children: [],
    },
    {
      path: "/init-password",
      name: "init_password",
      meta: {
        public: true,
      },
      component: () => import(/* webpackChunkName: "init_password" */ "@/views/InitPassword.vue"),
    },
    // Antares
    {
      path: "/registrierung",
      name: "gmbh_contact_form",
      meta: {
        noRedirectOnFailedIdentify: true,
      },
      component: () => import(/* webpackChunkName: "gmbh_contact_form" */ "@/views/GmbhContactForm.vue"),
    },
    {
      path: "/antares-form",
      name: "antares_form",
      meta: {
        noRedirectOnFailedIdentify: true,
      },
      component: () => import(/* webpackChunkName: "gmbh_contact_form" */ "@/views/GmbhContactForm.vue"),
      props: { titre: "Antares" },
    },
    {
      path: "*",
      name: "404",
      component: () => import(/* webpackChunkName: "404" */ "@/views/ErrorPage.vue"),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (!store.getters.isLogged && !to?.meta?.public) {
    // Await, pour être sûr que l'utilisateur soit connecté avant d'accéder à la page.
    await store.dispatch("login", { noRedirect: to?.meta?.noRedirectOnFailedIdentify });
  }

  if (["antares_form", "gmbh_contact_form"].includes(to.name)) {
    to.meta.public = !(store.getters.isLogged === true);
  }

  next();
});

export default router;
